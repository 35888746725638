<div class="home__container">
  <h1 class="home__header">Get Started</h1>
  <section>
    <p class="home__paragraph">
      RADUIS for Angular is available via the
      <code>@zs-ca/angular-cd-library</code>
      <a
        href="https://dev.azure.com/ZSCustomApps/raduis/_artifacts/feed/raduis/Npm/@zs-ca%2Fangular-cd-library/overview/3.3.0"
        target="_blank"
        rel="noopener noreferrer"
      >
        npm package.
      </a>
    </p>
  </section>
  <section>
    <h1>Versions</h1>
    <p>Current Tags:</p>
    <ul class="home__list">
      <li><code>v3.x</code> &ndash; latest</li>
      <li><code>v2.x</code> &ndash; v2</li>
      <li><code>v1.x</code> &ndash; v1</li>
    </ul>
  </section>
  <section>
    <h1>Angular Support</h1>
    <p class="home__paragraph">
      <code>@zs-ca/angular-cd-library</code> now supports Angular
      <code>^16.0.0</code>.
    </p>
  </section>
  <section>
    <h1>Quick Links</h1>
    <ul class="home__list">
      <li>
        To build using RADUIS components, please see our
        <a
          href="https://rd-angular-documentation.zssandbox.com/"
          target="_blank"
          rel="noopener noreferrer"
          >Documentation</a
        >.
      </li>
      <li>
        For an overview, see
        <a
          href="https://dev.azure.com/ZSCustomApps/_git/raduis?path=/README.md&_a=preview"
          target="_blank"
          rel="noopener noreferrer"
          >README.md</a
        >.
      </li>
      <li>
        We welcome contributions! See
        <a
          href="https://dev.azure.com/ZSCustomApps/raduis/_git/raduis?path=/packages/angular-cd/projects/angular-cd-library/README.md&_a=preview"
          target="_blank"
          rel="noopener noreferrer"
        >
          CONTRIBUTING.md
        </a>
        for details.
      </li>
      <li>
        All notable changes of the current major version are described in
        <a
          href="https://dev.azure.com/ZSCustomApps/raduis/_git/raduis?path=/packages/angular-cd/projects/angular-cd-library/CHANGELOG.md&_a=preview"
          target="_blank"
          rel="noopener noreferrer"
        >
          CHANGELOG.md </a
        >.
      </li>
      <li>
        <a
          href="https://dev.azure.com/ZSCustomApps/raduis/_git/raduis"
          target="_blank"
          rel="noopener noreferrer"
        >
          Azure Repository
        </a>
      </li>
    </ul>
  </section>
</div>
<div class="home__section-right">
  <rd-button
    rdStep stepId="stepEight"
    btnText="Explore RADUIS Components"
    btnType="primary"
    [routerLink]="['/components']"
  ></rd-button>
</div>
