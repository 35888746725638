import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DesignComponent } from './design/design.component';
import { DocsComponent } from './docs/docs.component';
import { HomeComponent } from './home/home.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { MsalRedirectComponent } from '@azure/msal-angular';


const routes: Routes = [
  {
    path: 'home',
    component: HomeComponent,
    data: { title: 'RADUIS - Home' },
  },
  {
    path: 'docs',
    component: DocsComponent,
    data: { title: 'RADUIS - Documents' },
  },
  {
    path: 'design',
    component: DesignComponent,
    data: { title: 'RADUIS - Design' },
  },
  {
    path: 'getAToken',
    component: MsalRedirectComponent,
  },
  {
    path: 'components',
    loadChildren: () =>
      import('./components/components.module').then((m) => m.ComponentsModule),
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
    data: { title: 'RADUIS - Home' },
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
