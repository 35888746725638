import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  template: `
    <rd-header [showActions]="true" appName="RADUIS Angular Showcase">
      <div rd-header-content>
        <rd-select
          mode="default"
          [options]="versionsAvailable"
          [selectedItems]="versionsAvailable[0]"
          [isBorderless]="true"
          (selectedItemsChange)="handleVersionChange($event)"
          placeholder="Select"
        >
        </rd-select>
      </div>
      <div rd-header-actions>
      {{userInfo?.name }}
    </div>
    </rd-header>

  `,
})
export class HeaderComponent {
  versionsAvailable = ['v3.x', 'v2.x', 'v1.x'];

  @Input() userInfo:any = {}

  handleVersionChange(version: string): void {
    const redirectUrl =
      {
        'v3.x': 'https://rd-angular.zssandbox.com/',
        'v2.x': 'https://rd-angular.zssandbox.com/v2/',
        'v1.x': 'https://rd-angular.zssandbox.com/v1/',
      }[version] || '';
    window.open(redirectUrl, '_self', 'noopener,noreferrer');
  }
}
