<app-sider></app-sider>
<div class="app-layout">
  <app-header [userInfo]="msalService.instance.getActiveAccount()"></app-header>
  <div class="app-content">
    <router-outlet></router-outlet>
  </div>
</div>

<!-- <ng-template #successToast let-data="data">
  <div style="display: flex; align-items: center">
    <rd-icon
      [iconStyle]="{ fontSize: '20px', marginRight: '0.5rem' }"
      type="custom:check-circle"
    ></rd-icon>
    {{ data?.message }}
  </div>
</ng-template>

<ng-template #errorToast let-data="data">
  <div style="display: flex; align-items: center">
    <rd-icon
      [iconStyle]="{ fontSize: '20px', marginRight: '0.5rem' }"
      type="custom:error-triangle"
    ></rd-icon>
    {{ data?.message }}
  </div>
</ng-template>

<ng-template #warningToast let-data="data">
  <div style="display: flex; align-items: center">
    <rd-icon
      [iconStyle]="{ fontSize: '20px', marginRight: '0.5rem' }"
      type="custom:error-square"
    ></rd-icon>
    {{ data?.message }}
  </div>
</ng-template>

<ng-template #infoToast let-data="data">
  <div style="display: flex; align-items: center">
    <rd-icon
      [iconStyle]="{ fontSize: '20px', marginRight: '0.5rem' }"
      type="custom:info-circle"
    ></rd-icon>
    {{ data?.message }}
  </div>
</ng-template>

<ng-template #customToast let-data="data">
  <div style="display: flex; align-items: center">
    <rd-icon
      [iconStyle]="{ fontSize: '20px', marginRight: '0.5rem' }"
      type="custom:info-circle"
    ></rd-icon>
    {{ data?.message }}
  </div>
</ng-template> -->

<!-- <ng-template #panelExtra>
  <div (click)="handlePanelHeaderExtraClick($event)">
    <rd-icon type="custom:error-square"></rd-icon>
    Extra
  </div>
</ng-template> -->
