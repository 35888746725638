export const CUSTOM_ICONS: Array<IconDefinition> = [
  {
    name: 'plus',
    icon: `
    <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="plus" aria-hidden="true"><defs><style></style></defs><path d="M482 152h60q8 0 8 8v704q0 8-8 8h-60q-8 0-8-8V160q0-8 8-8z"></path><path d="M176 474h672q8 0 8 8v60q0 8-8 8H176q-8 0-8-8v-60q0-8 8-8z"></path></svg>
    `,
  },
  {
    name: 'download',
    icon: `
     <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="download" aria-hidden="true"><path d="M505.7 661a8 8 0 0012.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
    `,
  },
  {
    name: 'diamond',
    icon: `
      <svg width="15" height="25" viewBox="0 0 15 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="7.26074" x2="7.26074" y2="25" stroke="#716E79" stroke-width="1.5"/>
      <rect x="7.36523" y="6.26891" width="8.91667" height="8.91667" transform="rotate(45 7.36523 6.26891)"  stroke="#716E79" stroke-width="1.5"/>
      </svg>
    `,
  },
  {
    name: 'home',
    icon: `
      <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.61 0.369977C10.4417 0.220951 10.2248 0.138672 10 0.138672C9.77524 0.138672 9.55825 0.220951 9.39 0.369977L0 8.90998H2.73V17.09C2.72905 17.2085 2.75153 17.3261 2.79615 17.4359C2.84077 17.5457 2.90665 17.6457 2.99 17.73C3.16293 17.9017 3.39633 17.9986 3.64 18H16.36C16.6037 17.9986 16.8371 17.9017 17.01 17.73C17.0934 17.6457 17.1592 17.5457 17.2038 17.4359C17.2485 17.3261 17.2709 17.2085 17.27 17.09V8.90998H20L10.61 0.369977Z" />
      </svg>
    `,
  },
  {
    name: 'database',
    icon: `
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M19.5 1.5H4.5C4.08516 1.5 3.75 1.83516 3.75 2.25V7.5H20.25V2.25C20.25 1.83516 19.9148 1.5 19.5 1.5ZM6.75 5.4375C6.23203 5.4375 5.8125 5.01797 5.8125 4.5C5.8125 3.98203 6.23203 3.5625 6.75 3.5625C7.26797 3.5625 7.6875 3.98203 7.6875 4.5C7.6875 5.01797 7.26797 5.4375 6.75 5.4375ZM3.75 21.75C3.75 22.1648 4.08516 22.5 4.5 22.5H19.5C19.9148 22.5 20.25 22.1648 20.25 21.75V16.5H3.75V21.75ZM6.75 18.5625C7.26797 18.5625 7.6875 18.982 7.6875 19.5C7.6875 20.018 7.26797 20.4375 6.75 20.4375C6.23203 20.4375 5.8125 20.018 5.8125 19.5C5.8125 18.982 6.23203 18.5625 6.75 18.5625ZM3.75 15H20.25V9H3.75V15ZM6.75 11.0625C7.26797 11.0625 7.6875 11.482 7.6875 12C7.6875 12.518 7.26797 12.9375 6.75 12.9375C6.23203 12.9375 5.8125 12.518 5.8125 12C5.8125 11.482 6.23203 11.0625 6.75 11.0625Z" />
      </svg>
    `,
  },
  {
    name: 'dictionary',
    icon: `
      <svg width="20" height="22" viewBox="0 0 20 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18.016 21.08H4.408C3.47232 21.08 2.57496 20.7083 1.91333 20.0467C1.2517 19.3851 0.880005 18.4877 0.880005 17.552V3.94404C0.880005 3.14203 1.1986 2.37286 1.76571 1.80575C2.33282 1.23864 3.10199 0.920044 3.904 0.920044H18.016C18.2833 0.920044 18.5397 1.02624 18.7288 1.21528C18.9178 1.40432 19.024 1.66071 19.024 1.92804V20.072C19.024 20.3394 18.9178 20.5958 18.7288 20.7848C18.5397 20.9738 18.2833 21.08 18.016 21.08ZM17.008 19.064V16.04H4.408C4.007 16.04 3.62241 16.1993 3.33886 16.4829C3.0553 16.7665 2.896 17.151 2.896 17.552C2.896 17.9531 3.0553 18.3376 3.33886 18.6212C3.62241 18.9047 4.007 19.064 4.408 19.064H17.008ZM7.936 2.93604V11L11.464 8.98404L14.992 11V2.93604H7.936Z" />
      </svg>
    `,
  },
  {
    name: 'bulb',
    icon: `
      <svg width="16" height="22" viewBox="0 0 16 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.15625 14.8461C1.85938 13.5172 0.3125 11.0328 0.3125 8.1875C0.3125 3.94297 3.75547 0.5 8 0.5C12.2445 0.5 15.6875 3.94297 15.6875 8.1875C15.6875 11.0328 14.1406 13.5172 11.8438 14.8461V17.5625C11.8438 17.9773 11.5086 18.3125 11.0938 18.3125H4.90625C4.49141 18.3125 4.15625 17.9773 4.15625 17.5625V14.8461ZM5.1875 19.8125H10.8125C10.9156 19.8125 11 19.8969 11 20V20.75C11 21.1648 10.6648 21.5 10.25 21.5H5.75C5.33516 21.5 5 21.1648 5 20.75V20C5 19.8969 5.08437 19.8125 5.1875 19.8125Z" />
      </svg>
    `,
  },
  {
    name: 'doc',
    icon: `
      <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 6V18.993C18.0009 19.1243 17.976 19.2545 17.9266 19.3762C17.8772 19.4979 17.8043 19.6087 17.7121 19.7022C17.6199 19.7957 17.5101 19.8701 17.3892 19.9212C17.2682 19.9723 17.1383 19.9991 17.007 20H0.993C0.729813 20 0.477391 19.8955 0.291196 19.7095C0.105001 19.5235 0.000265042 19.2712 0 19.008V0.992C0 0.455 0.449 0 1.002 0H11.997L18 6ZM16 7H11V2H2V18H16V7ZM5 5H8V7H5V5ZM5 9H13V11H5V9ZM5 13H13V15H5V13Z" fill="#5B5864"/>
      </svg>
    `,
  },
  {
    name: 'link',
    icon: `
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.66667 -0.000488281V1.33285H1.33333V10.6662H10.6667V7.33285H12V11.3328C12 11.5097 11.9298 11.6792 11.8047 11.8043C11.6797 11.9293 11.5101 11.9995 11.3333 11.9995H0.666667C0.489856 11.9995 0.320286 11.9293 0.195262 11.8043C0.0702379 11.6792 0 11.5097 0 11.3328V0.666178C0 0.489367 0.0702379 0.319798 0.195262 0.194774C0.320286 0.0697496 0.489856 -0.000488281 0.666667 -0.000488281H4.66667ZM9.724 1.33285H6.66667V-0.000488281H12V5.33285H10.6667V2.27551L6 6.94218L5.05733 5.99951L9.724 1.33285Z" fill="#454250"/>
      </svg>
    `,
  },
  {
    name: 'gear',
    icon: `
      <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.13197 12.63C0.952593 11.5507 0.952593 10.4493 1.13197 9.37001C2.23397 9.39601 3.22397 8.86801 3.60897 7.93901C3.99397 7.00901 3.66697 5.93501 2.86897 5.17601C3.50538 4.2853 4.28453 3.50581 5.17497 2.86901C5.93497 3.66701 7.00897 3.99401 7.93897 3.60901C8.86897 3.22401 9.39597 2.23301 9.36897 1.13201C10.4489 0.952416 11.551 0.952416 12.631 1.13201C12.604 2.23401 13.132 3.22401 14.061 3.60901C14.991 3.99401 16.065 3.66701 16.824 2.86901C17.7147 3.50542 18.4942 4.28458 19.131 5.17501C18.333 5.93501 18.006 7.00901 18.391 7.93901C18.776 8.86901 19.767 9.39601 20.868 9.36901C21.0476 10.4489 21.0476 11.5511 20.868 12.631C19.766 12.604 18.776 13.132 18.391 14.061C18.006 14.991 18.333 16.065 19.131 16.824C18.4946 17.7147 17.7154 18.4942 16.825 19.131C16.065 18.333 14.991 18.006 14.061 18.391C13.131 18.776 12.604 19.767 12.631 20.868C11.551 21.0476 10.4489 21.0476 9.36897 20.868C9.39597 19.766 8.86797 18.776 7.93897 18.391C7.00897 18.006 5.93497 18.333 5.17597 19.131C4.28525 18.4946 3.50577 17.7154 2.86897 16.825C3.66697 16.065 3.99397 14.991 3.60897 14.061C3.22397 13.131 2.23297 12.604 1.13197 12.631V12.63ZM11 14C11.7956 14 12.5587 13.6839 13.1213 13.1213C13.6839 12.5587 14 11.7957 14 11C14 10.2044 13.6839 9.4413 13.1213 8.87869C12.5587 8.31608 11.7956 8.00001 11 8.00001C10.2043 8.00001 9.44125 8.31608 8.87865 8.87869C8.31604 9.4413 7.99997 10.2044 7.99997 11C7.99997 11.7957 8.31604 12.5587 8.87865 13.1213C9.44125 13.6839 10.2043 14 11 14Z" />
      </svg>
    `,
  },
  {
    name: 'check-circle',
    icon: `
    <svg width='14' height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM10.0234 4.71406L6.73281 9.27656C6.68682 9.34076 6.62619 9.39306 6.55595 9.42914C6.48571 9.46523 6.40787 9.48405 6.32891 9.48405C6.24994 9.48405 6.17211 9.46523 6.10186 9.42914C6.03162 9.39306 5.97099 9.34076 5.925 9.27656L3.97656 6.57656C3.91719 6.49375 3.97656 6.37813 4.07812 6.37813H4.81094C4.97031 6.37813 5.12187 6.45469 5.21562 6.58594L6.32812 8.12969L8.78438 4.72344C8.87813 4.59375 9.02812 4.51562 9.18906 4.51562H9.92188C10.0234 4.51562 10.0828 4.63125 10.0234 4.71406Z" fill="#00AA67"/>
    </svg>
    `,
  },
  {
    name: 'info-circle',
    icon: `
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7.5 10.375C7.5 10.4438 7.44375 10.5 7.375 10.5H6.625C6.55625 10.5 6.5 10.4438 6.5 10.375V6.125C6.5 6.05625 6.55625 6 6.625 6H7.375C7.44375 6 7.5 6.05625 7.5 6.125V10.375ZM7 5C6.80374 4.99599 6.61687 4.91522 6.47948 4.775C6.3421 4.63478 6.26515 4.4463 6.26515 4.25C6.26515 4.0537 6.3421 3.86522 6.47948 3.725C6.61687 3.58478 6.80374 3.50401 7 3.5C7.19626 3.50401 7.38313 3.58478 7.52052 3.725C7.6579 3.86522 7.73485 4.0537 7.73485 4.25C7.73485 4.4463 7.6579 4.63478 7.52052 4.775C7.38313 4.91522 7.19626 4.99599 7 5Z" fill="#3B9FE7"/>
    </svg>
   `,
  },
  {
    name: 'error-square',
    icon: `
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.3333 0H0.666667C0.266667 0 0 0.266667 0 0.666667V11.3333C0 11.7333 0.266667 12 0.666667 12H11.3333C11.7333 12 12 11.7333 12 11.3333V0.666667C12 0.266667 11.7333 0 11.3333 0ZM6.66667 9.33333H5.33333V8H6.66667V9.33333ZM6.66667 7.33333H5.33333V2.66667H6.66667V7.33333Z" fill="#F3BA2F"/>
    </svg>
    `,
  },
  {
    name: 'error-triangle',
    icon: `
    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.59465 1.16656L13.9453 12.1666C14.0038 12.2679 14.0346 12.3829 14.0346 12.4999C14.0346 12.6169 14.0038 12.7319 13.9453 12.8332C13.8868 12.9346 13.8027 13.0187 13.7013 13.0772C13.6 13.1357 13.485 13.1666 13.368 13.1666H0.66665C0.549627 13.1666 0.434666 13.1357 0.333322 13.0772C0.231979 13.0187 0.147822 12.9346 0.0893122 12.8332C0.0308019 12.7319 -7.43221e-07 12.6169 0 12.4999C7.43248e-07 12.3829 0.0308049 12.2679 0.0893165 12.1666L6.43998 1.16656C6.4985 1.06522 6.58265 0.981067 6.684 0.92256C6.78534 0.864053 6.9003 0.833252 7.01732 0.833252C7.13434 0.833252 7.24929 0.864053 7.35064 0.92256C7.45198 0.981067 7.53614 1.06522 7.59465 1.16656ZM6.35065 9.83322V11.1666H7.68398V9.83322H6.35065ZM6.35065 5.16656V8.49989H7.68398V5.16656H6.35065Z" fill="#E65428"/>
    </svg>
    `,
  },
  {
    name: 'filter',
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" >
    <path d="M10 18H14V16H10V18ZM3 6V8H21V6H3ZM6 13H18V11H6V13Z"/>
    </svg>`,
  },
  {
    name: 'twitter',
    icon: `<svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="twitter" aria-hidden="true"><path d="M928 254.3c-30.6 13.2-63.9 22.7-98.2 26.4a170.1 170.1 0 0075-94 336.64 336.64 0 01-108.2 41.2A170.1 170.1 0 00672 174c-94.5 0-170.5 76.6-170.5 170.6 0 13.2 1.6 26.4 4.2 39.1-141.5-7.4-267.7-75-351.6-178.5a169.32 169.32 0 00-23.2 86.1c0 59.2 30.1 111.4 76 142.1a172 172 0 01-77.1-21.7v2.1c0 82.9 58.6 151.6 136.7 167.4a180.6 180.6 0 01-44.9 5.8c-11.1 0-21.6-1.1-32.2-2.6C211 652 273.9 701.1 348.8 702.7c-58.6 45.9-132 72.9-211.7 72.9-14.3 0-27.5-.5-41.2-2.1C171.5 822 261.2 850 357.8 850 671.4 850 843 590.2 843 364.7c0-7.4 0-14.8-.5-22.2 33.2-24.3 62.3-54.4 85.5-88.2z"></path></svg>
    `,
  },
  {
    name: 'youtube',
    icon: `<svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="youtube" aria-hidden="true"><path d="M960 509.2c0-2.2 0-4.7-.1-7.6-.1-8.1-.3-17.2-.5-26.9-.8-27.9-2.2-55.7-4.4-81.9-3-36.1-7.4-66.2-13.4-88.8a139.52 139.52 0 00-98.3-98.5c-28.3-7.6-83.7-12.3-161.7-15.2-37.1-1.4-76.8-2.3-116.5-2.8-13.9-.2-26.8-.3-38.4-.4h-29.4c-11.6.1-24.5.2-38.4.4-39.7.5-79.4 1.4-116.5 2.8-78 3-133.5 7.7-161.7 15.2A139.35 139.35 0 0082.4 304C76.3 326.6 72 356.7 69 392.8c-2.2 26.2-3.6 54-4.4 81.9-.3 9.7-.4 18.8-.5 26.9 0 2.9-.1 5.4-.1 7.6v5.6c0 2.2 0 4.7.1 7.6.1 8.1.3 17.2.5 26.9.8 27.9 2.2 55.7 4.4 81.9 3 36.1 7.4 66.2 13.4 88.8 12.8 47.9 50.4 85.7 98.3 98.5 28.2 7.6 83.7 12.3 161.7 15.2 37.1 1.4 76.8 2.3 116.5 2.8 13.9.2 26.8.3 38.4.4h29.4c11.6-.1 24.5-.2 38.4-.4 39.7-.5 79.4-1.4 116.5-2.8 78-3 133.5-7.7 161.7-15.2 47.9-12.8 85.5-50.5 98.3-98.5 6.1-22.6 10.4-52.7 13.4-88.8 2.2-26.2 3.6-54 4.4-81.9.3-9.7.4-18.8.5-26.9 0-2.9.1-5.4.1-7.6v-5.6zm-72 5.2c0 2.1 0 4.4-.1 7.1-.1 7.8-.3 16.4-.5 25.7-.7 26.6-2.1 53.2-4.2 77.9-2.7 32.2-6.5 58.6-11.2 76.3-6.2 23.1-24.4 41.4-47.4 47.5-21 5.6-73.9 10.1-145.8 12.8-36.4 1.4-75.6 2.3-114.7 2.8-13.7.2-26.4.3-37.8.3h-28.6l-37.8-.3c-39.1-.5-78.2-1.4-114.7-2.8-71.9-2.8-124.9-7.2-145.8-12.8-23-6.2-41.2-24.4-47.4-47.5-4.7-17.7-8.5-44.1-11.2-76.3-2.1-24.7-3.4-51.3-4.2-77.9-.3-9.3-.4-18-.5-25.7 0-2.7-.1-5.1-.1-7.1v-4.8c0-2.1 0-4.4.1-7.1.1-7.8.3-16.4.5-25.7.7-26.6 2.1-53.2 4.2-77.9 2.7-32.2 6.5-58.6 11.2-76.3 6.2-23.1 24.4-41.4 47.4-47.5 21-5.6 73.9-10.1 145.8-12.8 36.4-1.4 75.6-2.3 114.7-2.8 13.7-.2 26.4-.3 37.8-.3h28.6l37.8.3c39.1.5 78.2 1.4 114.7 2.8 71.9 2.8 124.9 7.2 145.8 12.8 23 6.2 41.2 24.4 47.4 47.5 4.7 17.7 8.5 44.1 11.2 76.3 2.1 24.7 3.4 51.3 4.2 77.9.3 9.3.4 18 .5 25.7 0 2.7.1 5.1.1 7.1v4.8zM423 646l232-135-232-133z"></path></svg>`,
  },
  {
    name: 'facebook',
    icon: `<svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="facebook" aria-hidden="true"><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-32 736H663.9V602.2h104l15.6-120.7H663.9v-77.1c0-35 9.7-58.8 59.8-58.8h63.9v-108c-11.1-1.5-49-4.8-93.2-4.8-92.2 0-155.3 56.3-155.3 159.6v89H434.9v120.7h104.3V848H176V176h672v672z"></path></svg>`,
  },
  {
    name: 'linkedin',
    icon: `<span nz-icon="" nztype="linkedin" class="anticon anticon-linkedin"><svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="linkedin" aria-hidden="true"><path d="M847.7 112H176.3c-35.5 0-64.3 28.8-64.3 64.3v671.4c0 35.5 28.8 64.3 64.3 64.3h671.4c35.5 0 64.3-28.8 64.3-64.3V176.3c0-35.5-28.8-64.3-64.3-64.3zm0 736c-447.8-.1-671.7-.2-671.7-.3.1-447.8.2-671.7.3-671.7 447.8.1 671.7.2 671.7.3-.1 447.8-.2 671.7-.3 671.7zM230.6 411.9h118.7v381.8H230.6zm59.4-52.2c37.9 0 68.8-30.8 68.8-68.8a68.8 68.8 0 10-137.6 0c-.1 38 30.7 68.8 68.8 68.8zm252.3 245.1c0-49.8 9.5-98 71.2-98 60.8 0 61.7 56.9 61.7 101.2v185.7h118.6V584.3c0-102.8-22.2-181.9-142.3-181.9-57.7 0-96.4 31.7-112.3 61.7h-1.6v-52.2H423.7v381.8h118.6V604.8z"></path></svg></span>`,
  },
  {
    name: 'sync',
    icon: `
    <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="sync" aria-hidden="true" class="anticon-spin"><path d="M168 504.2c1-43.7 10-86.1 26.9-126 17.3-41 42.1-77.7 73.7-109.4S337 212.3 378 195c42.4-17.9 87.4-27 133.9-27s91.5 9.1 133.8 27A341.5 341.5 0 01755 268.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 003 14.1l175.7 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c0-6.7-7.7-10.5-12.9-6.3l-56.4 44.1C765.8 155.1 646.2 92 511.8 92 282.7 92 96.3 275.6 92 503.8a8 8 0 008 8.2h60c4.4 0 7.9-3.5 8-7.8zm756 7.8h-60c-4.4 0-7.9 3.5-8 7.8-1 43.7-10 86.1-26.9 126-17.3 41-42.1 77.8-73.7 109.4A342.45 342.45 0 01512.1 856a342.24 342.24 0 01-243.2-100.8c-9.9-9.9-19.2-20.4-27.8-31.4l60.2-47a8 8 0 00-3-14.1l-175.7-43c-5-1.2-9.9 2.6-9.9 7.7l-.7 181c0 6.7 7.7 10.5 12.9 6.3l56.4-44.1C258.2 868.9 377.8 932 512.2 932c229.2 0 415.5-183.7 419.8-411.8a8 8 0 00-8-8.2z"></path></svg>
    `,
  },
  {
    name: 'close-circle',
    icon: `
    <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="close-circle" aria-hidden="true"><path d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 00-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"></path><path d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path></svg>
    `,
  },
  {
    name: 'exclamation-circle',
    icon: `
    <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="exclamation-circle" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M464 688a48 48 0 1096 0 48 48 0 10-96 0zm24-112h48c4.4 0 8-3.6 8-8V296c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8z"></path></svg>
    `,
  },
  {
    name: 'clock-circle',
    icon: `
    <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em" data-icon="clock-circle" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M686.7 638.6L544.1 535.5V288c0-4.4-3.6-8-8-8H488c-4.4 0-8 3.6-8 8v275.4c0 2.6 1.2 5 3.3 6.5l165.4 120.6c3.6 2.6 8.6 1.8 11.2-1.7l28.6-39c2.6-3.7 1.8-8.7-1.8-11.2z"></path></svg>
    `,
  },
];

export interface IconDefinition {
  name: string;
  icon: string;
}
