import { Component, OnInit } from '@angular/core';
import { IconService } from '@core/services/icon.service';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from '@azure/msal-browser';
import { filter, Subject, takeUntil } from 'rxjs';

declare const gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  unsubscribe: Subject<any> = new Subject();
  userInfo: any;

  constructor(
    public router: Router,
    private titleService: Title,
    private iconService: IconService,
    private activatedRoute: ActivatedRoute,
    public msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService
  ) {
    this.iconService.registerIcons();
    this.addGAScript();
    this.handleRouteEvents();
  }

  ngOnInit() {
    this.initiateMSAL();
    this.router.events.pipe().subscribe(() => {
      const rt = this.getChild(this.activatedRoute);
      rt.data.subscribe((data: any) => {
        if (data?.title) {
          this.titleService.setTitle(data.title);
        }
      });
    });
  }

  initiateMSAL() {
    this.msalBroadcastService.inProgress$.subscribe(() => {
      this.setAuthenticateStatus();
    });

    this.msalBroadcastService.msalSubject$.subscribe(
      (message: any) => {
        if (message.eventType === 'msal:loginSuccess') {
          const authResult = message.payload as AuthenticationResult;
          this.userInfo = authResult.account;
          this.msalService.instance.setActiveAccount(authResult.account);
        }
      }
    );
  }

  logout(): void {
    this.msalService.instance.logoutRedirect();
  }

  login(): void {
    this.msalService.instance.loginRedirect();
  }

  setAuthenticateStatus() {
    let activeAccount = this.msalService.instance.getActiveAccount();
    if (
      !activeAccount &&
      this.msalService.instance.getAllAccounts().length > 0
    ) {
      activeAccount = this.msalService.instance.getAllAccounts()[0];
      this.userInfo = activeAccount;
      this.msalService.instance.setActiveAccount(activeAccount);
    }
    if (!!activeAccount) {
      this.getIdToken();
      setTimeout(() => {
        this.router.navigate(['/home']);
      });
    } else {
      setTimeout(() => {
        this.login();
      });
    }
  }

  getIdToken() {
    Object.keys(sessionStorage).map((item: string) => {
      try {
        let obj = JSON.parse(sessionStorage[item]);
        if (obj?.credentialType === 'IdToken') {
          sessionStorage.setItem('idToken', obj.secret);
        }
      } catch (error) {}
    });
  }

  getChild(activatedRoute: ActivatedRoute): any {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  addGAScript() {
    let gtagScript: HTMLScriptElement = document.createElement('script');
    gtagScript.async = true;
    gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-M2ZZK1NM6E';
    document.head.prepend(gtagScript);
    gtag('config', 'G-M2ZZK1NM6E', { send_page_view: false });
  }

  handleRouteEvents() {
    this.router.events.pipe().subscribe((event) => {
      if (event instanceof NavigationEnd && event?.urlAfterRedirects) {
        gtag('event', 'page_view', {
          page_path: event.urlAfterRedirects,
        });
      }
    });
  }
}
