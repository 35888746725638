<div class="home__container">
  <h1 class="home__header">RADUIS Design</h1>
  <section>
    <h1>Introduction</h1>
    <!-- TODO: Update content. -->
    <p>
      RADUIS has a large number of enterprise-level products. With complex
      scenarios, designers and developers often need to respond fast due to
      frequent changes in product demands and concurrent R&D workflow. Many
      similar contents exist in the process. Through abstraction, we could
      obtain some stable and highly reusable components and pages. On the other
      hand, with the trend of commercialization, more and more enterprise
      products begin to pursue better user experiences. Under this situation,
      RADUIS Team builds a design system for ZS enterprise products based on
      four design values of Natural, Certain, Meaningful, and Growing. It aims
      to uniform the user interface specs and reduce redundancies and excessive
      production costs, helping product designers to focus on better user
      experience.
    </p>
  </section>
  <section>
    <h1>Guidelines and Resources</h1>
    <p>
      We provide comprehensive design guidelines, best practices, resources, and
      tools to help designers produce high-quality product prototypes.
    </p>
  </section>
  <section>
    <h1>Who's using RADUIS</h1>
    <ul class="home__list">
      <li>
        <a
          href="https://pac-demo.zsservices.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Patient Analytics Cloud
        </a>
      </li>
      <li>
        <a
          href="https://ptj.zsservices.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Patient Journey
        </a>
      </li>
      <li>
        <a
          href="https://data-explorer.zsservices.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Data Explorer
        </a>
      </li>
      <li>
        <a
          href="https://concept-builder.qa.zsservices.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Concept Builder
        </a>
      </li>
      <li>
        <a
          href="https://oli-shrd.zsservices.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          OLI
        </a>
      </li>
      <li>
        <a
          href="https://p2p.zsservices.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Peer-to-Peer
        </a>
      </li>
      <li>
        <a
          href="https://prdr-staging.zsservices.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Patient Radar
        </a>
      </li>
      <li>
        <a
          href="https://tcp-staging.zsservices.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          True Customer Potential
        </a>
      </li>
    </ul>
  </section>
</div>
<div class="home__section-right">
  <rd-button
    rdStep
    stepId="stepSeven"
    btnText="Get Started"
    btnType="primary"
    [routerLink]="['/docs']"
  ></rd-button>
</div>
