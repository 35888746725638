import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RdSiderMenuItem } from 'angular-cd-library';

@Component({
  selector: 'app-sider',
  templateUrl: './sider.component.html',
  styleUrls: ['./sider.component.scss'],
})
export class SiderComponent {
  readonly menuItemsList: RdSiderMenuItem[] = [
    {
      label: 'Home',
      path: '/home',
      icon: 'custom:home',
    },
    {
      label: 'Design',
      path: '/design',
      icon: 'custom:bulb',
    },
    {
      label: 'Docs',
      path: '/docs',
      icon: 'custom:dictionary',
    },
    {
      label: 'Components',
      path: '/components',
      icon: 'custom:database',
    },
  ];

  showHelpModal = false;

  readonly contactSupportList = [
    {
      label: 'Email',
      value: 'support@zs.com',
      href: 'mailto:',
    },
    {
      label: 'Phone',
      value: '+91 9xxxx xxxx0',
      href: 'tel:',
    },
  ];

  readonly releaseVersion = '3.0.0';

  constructor(private router: Router) {}

  toggleSupportModal($event: boolean): void {
    this.showHelpModal = $event;
  }

  handleBrandClick(): void {
    this.router.navigateByUrl('/');
  }
}
