import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  en_US,
  RdTourModule,
  RDButtonModule,
  RDHeaderModule,
  RDIconModule,
  RDModalModule,
  RDNotificationModule,
  RDSelectModule,
  RDSiderModule,
  RDTileModule,
  RdAvatarModule,
  RD_I18N,
} from 'angular-cd-library';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DesignComponent } from './design/design.component';
import { DocsComponent } from './docs/docs.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './layout/header/header.component';
import { SiderComponent } from './layout/sider/sider.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { environment } from 'src/environments/environment';
import {
  MSAL_INSTANCE,
  MsalBroadcastService,
  MsalRedirectComponent,
  MsalService,
} from '@azure/msal-angular';
import {
  IPublicClientApplication,
  PublicClientApplication,
} from '@azure/msal-browser';

function MsalInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.azureSSO.CLIENT_ID,
      authority: environment.azureSSO.AUTHORITY,
      redirectUri: environment.azureSSO.REDIRECT_PATH,
    },
  });
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    SiderComponent,
    DesignComponent,
    DocsComponent,
    NotFoundComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    RdTourModule,
    RDButtonModule,
    RDHeaderModule,
    RDIconModule,
    RDModalModule,
    RDNotificationModule,
    RDSelectModule,
    RDSiderModule,
    RDTileModule,
    RdAvatarModule,
  ],
  providers: [Title, 
    { provide: RD_I18N, useValue: en_US }, 
    { provide: MSAL_INSTANCE, useFactory: MsalInstanceFactory},
  MsalService,
  MsalBroadcastService,
]
  ,
  bootstrap: [AppComponent, MsalRedirectComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
