import { Injectable } from '@angular/core';
import { RDIconService } from 'angular-cd-library';
import { CUSTOM_ICONS } from '../constants';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  constructor(private iconService: RDIconService) {}

  /**
   * Register icons to library.
   */
  registerIcons() {
    CUSTOM_ICONS.forEach((customIcon) => {
      this.iconService.addIconLiteral(
        `custom:${customIcon.name}`,
        customIcon.icon
      );
    });
  }
}
