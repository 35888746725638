<div class="home">
  <div class="home__banner">
    <h1 class="home__title" rdStep stepId="stepOne">RADUIS</h1>
    <!-- Rapid Application Development UI Structures -->
    <h1 class="home__subtitle">
      A UI library developed by the ZS Custom Applications team for engineers.
    </h1>
    <div class="home__navigation">
      <rd-button
        rdStep
        stepId="stepFive"
        btnText="Learn more"
        btnType="link"
        [btnStyle]="{ height: '40px', padding: 0 }"
        [routerLink]="['/design']"
      ></rd-button>
      <rd-button
        rdStep
        stepId="stepSix"
        btnText="Get Started"
        btnType="primary"
        [routerLink]="['/docs']"
      ></rd-button>
    </div>
  </div>
  <div class="home__documents">
    <div class="home__tiles">
      <rd-tile
        mode="checkable"
        [(checked)]="tileChecked"
        (checkedChange)="handleHomeTileClick('rd-angular-documentation')"
      >
        <div class="home__tile-content" rdStep stepId="stepTwo">
          Components Documentation
          <rd-icon
            type="custom:link"
            [iconStyle]="{ fontSize: '12px' }"
          ></rd-icon>
        </div>
      </rd-tile>
      <rd-tile
        mode="checkable"
        [(checked)]="tileChecked"
        (checkedChange)="handleHomeTileClick('raduis-azure')"
      >
        <div class="home__tile-content" rdStep stepId="stepThree">
          Repository
          <rd-icon
            type="custom:link"
            [iconStyle]="{ fontSize: '12px' }"
          ></rd-icon>
        </div>
      </rd-tile>
      <rd-tile
        mode="checkable"
        [(checked)]="tileChecked"
        (checkedChange)="handleHomeTileClick('rd-react')"
      >
        <div class="home__tile-content" rdStep stepId="stepFour">
          RADUIS for React
          <rd-icon
            type="custom:link"
            [iconStyle]="{ fontSize: '12px' }"
          ></rd-icon>
        </div>
      </rd-tile>
    </div>
    <div class="home__tour" (click)="startTour()">
      <rd-button
        btnText="TAKE A TOUR"
        btnType="link"
        [btnStyle]="{
          height: '32px',
          padding: 0,
          'font-size': '14px',
          'font-weight': '600',
          'white-space': 'normal'
        }"
      ></rd-button>
    </div>
  </div>
</div>
