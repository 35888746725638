// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  config: {
    loginUrl: 'https://rd-angular.zssandbox.com/redirect',
    logoutUrl: 'https://rd-angular.zssandbox.com/logout',
    staticJSON: false,
  },
  azureSSO: {
    CLIENT_ID: 'aa491558-b7e5-4bde-b5ac-7d502abd30cc',
    AUTHORITY:'https://login.microsoftonline.com/ec3c7dee-d552-494b-a393-7f941a90b985',
    REDIRECT_PATH: '/getAToken',
    ENDPOINT: 'https://graph.microsoft.com/v1.0/users',
    ENDPOINT_ME: 'https://graph.microsoft.com/v1.0/me',
    SCOPE: ['User.ReadBasic.All'],
    SESSION_TYPE: 'filesystem',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
