import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { RdTourService } from 'angular-cd-library';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  tileChecked = false;

  constructor(private router: Router, private rdTourService: RdTourService) {}

  redirectToComponents(): void {
    this.router.navigateByUrl('/components');
  }

  handleHomeTileClick(redirectTo: string): void {
    const redirectUrl =
      {
        'rd-angular-documentation':
          'https://rd-angular-documentation.zssandbox.com/',
        'rd-react': 'https://rd-react.zssandbox.com/',
        'raduis-azure': 'https://dev.azure.com/ZSCustomApps/raduis/',
      }[redirectTo] || '';
    window.open(redirectUrl, '_blank', 'noopener,noreferrer');
    setTimeout(() => {
      this.tileChecked = false;
    });
  }

  startTour() {
    const options = {
      steps: [
        {
          stepId: 'stepOne',
          title: 'Welcome',
          position: ['left'],
          description: 'Rapid Application Development UI Structures (RADUIS) is a state-of-the-art web technology-enabled framework developed by the Custom Applications team for engineers to consume standardized unit tested controls',
        },
        {
          stepId: 'stepTwo',
          title: 'RADUIS for Angular',
          position: ['left'],
          description: "Click the button below to instantly access the documentation. This will take you to a comprehensive resource that provides in-depth insights and guidance on effectively utilizing RADUIS within your Angular project.",
        },
        {
          stepId: 'stepThree',
          title: 'Contribute',
          position: ['top'],
          description: 'Click the button below to be seamlessly direct to the RADUIS repository.',
        },
        {
          stepId: 'stepFour',
          title: 'RADUIS for react',
          position: ['right'],
          description: 'Click on the button below to be directed to the RADUIS React documentation. This will take you to a comprehensive resource that provides in-depth insights and guidance on effectively utilizing RADUIS within your React project.',
        },
        {
          stepId: 'stepFive',
          title: 'Explore',
          position: ['left'],
          description: "Explore further by clicking on 'Learn More' to access additional information regarding RADUIS Guidelines and Resources, as well as to discover the projects that are currently integrated using RADUIS into their framework.",
        },
        {
          stepId: 'stepSix',
          title: ' Getting Started',
          path: '/home',
          position: ['right'],
          description: "Start exploring now – just click the 'Get Started' button to browse through RADUIS Design.",
        },
        {
          stepId: 'stepSeven',
          title: 'Documentation',
          path: '/design',
          position: ['leftBottom'],
          description: "Click the 'Get Started' button to access the quick links.",
        },
        {
          stepId: 'stepEight',
          title: 'Explore Components',
          path: '/docs',
          position: ['leftBottom'],
          description: "Click the 'Explore RADUIS Components' button to explore components.",
        },
      ],
      startWith: '',
      showCounter: true,
      showPrevButton: true,
      stepDefaultPosition: 'bottom',
    };
    this.rdTourService.startTour(options).subscribe(
      (step) => {
        console.log('Next:', step);
      },
      (e) => {
        console.log('Error', e);
      },
      () => {
        console.log('Tour finished');
      }
    );
  }
}
